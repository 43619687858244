<template>
  <div>
    <el-row :gutter="24" >
      <el-col :span="18" class="hidden-sm-and-down" :offset="4">
        <div class="left">
          <div class="logoBox" style="margin-top: 20px;"> 
            <img src="../assets/logo.png" alt="" class="logo" @click="toLink('/')">
          </div>
        </div>
       <div class="right" style="width: 50%;line-height: 88px;">
          <el-row>
            <el-col :span="1"></el-col>
            <el-col :span="7" :class="activeIndex == 1?'active menu':'menu'" ><div @click="toLink('/')" style="height: 100%;">首页</div></el-col>
            <el-col :span="1"></el-col>
            <el-col :span="7" :class="activeIndex == 2?'active menu':'menu'" ><div @click="toLink('/business')" style="height: 100%;">传家业务</div> </el-col>
            <el-col :span="1"></el-col>
            <el-col :span="7" :class="activeIndex == 3?'active menu':'menu'" ><div @click="toLink('/team')" style="height: 100%;">传家团队</div></el-col>
          </el-row>
       </div>
      </el-col>
      <el-col :span='20' class=" hidden-md-and-up" :offset="2">
        <div class="left">
          <div class="logoBox" style="margin-top: 20px;"> 
            <img src="../assets/logo.png" alt="" class="logo" @click="toLink('/')">
          </div>
        </div>
       <div class="right" style="width: 50%;line-height: 88px;" >
          <div class="line-container" v-if="!statusbar" @click="statusbar = true">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
       </div>
      </el-col>
    </el-row>
    <div v-if="statusbar"  style="position: fixed;top: 0;z-index: 100;width: 100%;">
      <div class="top"> <i class="el-icon-close" style="font-size: 50px;color:#fff;position: absolute;right: 50px;top: 20px;" @click="statusbar = false"></i></div>
      <div style="margin: 0 auto;width: 200px;margin-top: 74px;">
          <el-row>
            <el-col :span="24" :class="activeIndex == 1?'appActive appMenu':'appMenu'"><div  @click="toLink('/')">首页</div></el-col>
            <el-col :span="24" :class="activeIndex == 2?'appActive appMenu':'appMenu'"><div  @click="toLink('/business')">传家业务</div> </el-col>
            <el-col :span="24" :class="activeIndex == 3?'appActive appMenu':'appMenu'" ><div @click="toLink('/team')">传家团队</div> </el-col>
          </el-row>
      </div>
    </div>
    <div style="width: 100%;" class="hidden-sm-and-down">
      <img :src="banner.picUrl" alt="" style="width: 100%;">
    </div>
    <div style="width: 100%;margin-top: 1rem;" class="hidden-md-and-up">
      <!-- <img :src="banner.picUrl" alt="" style="height: 200px;" > -->
      <el-image :src="banner.appUrl" style="height: 200px;width: 100%;" fit="cover"></el-image>
    </div>
    <div style="width: 100%;" class="hidden-sm-and-down">
      <img :src="info.picUrl" alt="" style="width: 100%;">
    </div>
    <div style="width: 100%;" class="hidden-md-and-up">
      <img :src="info.appUrl" alt="" style="width: 100%;">
    </div>
    <div class="footer hidden-sm-and-down">
      <el-row>
        <el-col :span="13" :offset="8">
          <div style="max-width: 167px;height: 126px;margin-top: 63px; float: left;">
            <img src="../assets/logo.png" alt="" style="width: 100%;height: 126px;">
          </div>
          <div
            style="float: right;font-weight: bold;font-size: 36px;margin-top: 24px;margin-bottom: 24px;">
            <div>联系我们</div>
            <div style="width: 26rem;height: 2px; background-color:#000;margin-top: 12px;margin-bottom: 12px;"></div>
            <div style="margin-bottom:12px ;font-size: 22px;font-weight: 400;">联系电话：{{ about.phone }} </div>
            <div style="margin-bottom:12px ;font-size: 22px;font-weight: 400;">官方邮箱：{{ about.email }}</div>
            <div style="margin-bottom:12px ;font-size: 22px;font-weight: 400;">公司地址：{{ about.address }}</div>
          </div>
        </el-col>
      </el-row>
      <div style="width: 100%;background-color: #2C2726;height: 60px;">
        <el-row>
          <el-col :span="16" :offset="8">
            <div style="float: left;color: #FFFFFF;font-size: 16px;font-weight: 400;line-height: 60px;margin-left:-42px ;">©2024 北京传承家办管理咨询有限公司</div>
            <div style="float: left;color: #FFFFFF;font-size: 16px;font-weight: 400;margin-left: 102px;line-height: 60px;">备案：京ICP备2024066754号-2</div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="footerApp  hidden-md-and-up" >
        <div :span="24" style="text-align: center;">
          <div style="font-size: 1rem;font-weight: bold">联系我们</div>
          <div style="width: 100%;margin: 0 auto;height: 2px;background-color:#D2041A  ;margin-top: 12px;margin-bottom: 12px;"></div>
          <div style="margin-bottom:12px ;font-size: 1rem;font-weight: 400;">联系电话：{{ about.phone }}</div>
          <div style="margin-bottom:12px ;font-size: 1rem;font-weight: 400;">官方邮箱：{{ about.email }}</div>
          <div style="margin-bottom:12px ;font-size: 1rem;font-weight: 400;">公司地址：{{ about.address }}</div>
        </div>
        <div :span="24" style="width: 100%;background-color: #2C2726;position: absolute;bottom: 0;">
          <div style="color: #FFFFFF;font-size: .8rem;font-weight: 400;text-align: center;margin-top: 20px;">©2024 北京传承家办管理咨询有限公司</div>
          <div style="color: #FFFFFF;font-size: .8rem;font-weight: 400;line-height: 45px;text-align: center">备案：京ICP备2024066754号-2</div>
        </div>
      </div>
  </div>
</template>

<script>

export default {
    name:'home',
    data() {
      return {
        activeIndex: 2,
        statusbar:false,
        about:{},
        banner:{picUrl:''},
        info:{picUrl:''}
      };
    },
    mounted() {
      document.title = '传家业务'; // 设置页面标题
      this.getData()
    },
    methods: {
      toLink(url){
        console.log(url)
        this.$router.push({path:url})
        this.statusbar = false
      },
      handleSelect(key, keyPath) {
        console.log(key, keyPath);
      },
      getData() {
        this.$http.get('api/desk/banner-list?detailType=4').then(res => {
          if(res.code == 200){
            this.banner = res.data[0]
            console.log(this.banner)
          }
        })
        this.$http.get('api/desk/banner-list?type=4').then(res => {
          if(res.code == 200){
            this.info = res.data[0]
          }
        })
        this.$http.get('api/desk/contact-us').then(res => {
          if(res.code == 200){
            this.about = res.data
          }
        })
      },
    }
}
</script>

<style>
.footer {
  width: 100%;
  height: 16.6rem;
  background: url('../assets/footer.jpg') no-repeat;
  background-size: 100% 100%;
  padding-top: 1rem;
  position: relative;
}

.top{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.line-container {
  margin-top: 25px;
  float: right;
}
 
.line {
  width: 35px; /* 横线的宽度 */
  height: 2px; /* 横线的高度 */
  margin-top: 10px;
  background-color: #3E302D; /* 横线的颜色 */
}
 
.line:not(:last-child) {
  margin-right: 10px; /* 横线之间的间隔 */
}
.appMenu{
  color:#fff;
  font-size:20px ;
  font-weight: bold;
  text-align: center;
  position: relative;
  cursor: pointer;
  margin-bottom: 50px;
}
.appActive::after{
  content: "";
  display: block;
  width: 53px;
  height: 4px;
  background: #F4AD05; /* 边框的颜色 */
  position: absolute;
  left: 50%;
  margin-top: 10px;
  border-radius: 2px;
  transform: translateX(-50%);
}
.menu{
  color:#3E302D;
  font-size:20px ;
  font-weight: bold;
  text-align: center;
  position: relative;
  cursor: pointer;
}
.active::after{
  content: "";
  display: block;
  width: 53px;
  height: 4px;
  background: #F4AD05; /* 边框的颜色 */
  position: absolute;
  left: 50%;
  margin-top: -18px;
  border-radius: 2px;
  transform: translateX(-50%);
}
.left{
  float: left;
}
.right{
  float: right;
}
.logoBox{
  width: 74px;
  height: 54px;
}
.logo{
  width: 100%;
  height: 54px;
  line-height: 88px;
  cursor: pointer;
}
.el-menu--horizontal > .el-menu-item.is-active {

  border-bottom: 2px solid #F4AD05 !important;
}
</style>