<template>
  <div>
    <el-row :gutter="24">
      <el-col :span="18" class="hidden-sm-and-down" :offset="4">
        <div class="left">
          <div class="logoBox" style="margin-top: 20px">
            <img src="../assets/logo.png" alt="" class="logo" @click="toLink('/')" />
          </div>
        </div>
        <div class="right" style="width: 50%; line-height: 88px">
          <el-row>
            <el-col :span="1"></el-col>
            <el-col :span="7" :class="activeIndex == 1 ? 'active menu' : 'menu'">
              <div @click="toLink('/')" style="height: 100%">首页</div>
            </el-col>
            <el-col :span="1"></el-col>
            <el-col :span="7" :class="activeIndex == 2 ? 'active menu' : 'menu'">
              <div @click="toLink('/business')" style="height: 100%">
                传家业务
              </div>
            </el-col>
            <el-col :span="1"></el-col>
            <el-col :span="7" :class="activeIndex == 3 ? 'active menu' : 'menu'">
              <div @click="toLink('/team')" style="height: 100%">传家团队</div>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :span="20" class="hidden-md-and-up" :offset="2">
        <div class="left">
          <div class="logoBox" style="margin-top: 20px">
            <img src="../assets/logo.png" alt="" class="logo" @click="toLink('/')" />
          </div>
        </div>
        <div class="right" style="width: 50%; line-height: 88px">
          <div class="line-container" v-if="!statusbar" @click="statusbar = true">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div v-if="statusbar" style="position: fixed; top: 0; z-index: 100; width: 100%">
      <div class="top">
        <i class="el-icon-close" style="
            font-size: 50px;
            color: #fff;
            position: absolute;
            right: 50px;
            top: 20px;
          " @click="statusbar = false"></i>
      </div>
      <div style="margin: 0 auto; width: 200px; margin-top: 74px">
        <el-row>
          <el-col :span="24" :class="activeIndex == 1 ? 'appActive appMenu' : 'appMenu'">
            <div @click="toLink('/')">首页</div>
          </el-col>
          <el-col :span="24" :class="activeIndex == 2 ? 'appActive appMenu' : 'appMenu'">
            <div @click="toLink('/business')">传家业务</div>
          </el-col>
          <el-col :span="24" :class="activeIndex == 3 ? 'appActive appMenu' : 'appMenu'">
            <div @click="toLink('/team')">传家团队</div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div style="width: 100%" class="hidden-sm-and-down">
      <img :src="banner.picUrl" alt="" style="width: 100%" />
    </div>
    <div style="width: 100%;margin-top: 1rem;" class="hidden-md-and-up">
      <!-- <img :src="banner.picUrl" alt="" style="height: 200px;" > -->
      <el-image :src="banner.appUrl" style="height: 200px;width: 100%;" fit="cover"></el-image>
    </div>
    <div style="width: 100%" class="hidden-sm-and-down">
      <el-row>
        <el-col :span="18" :offset="4">
          <div class="search" style="margin-top: 32px">
            <el-row class="row-bg" >
              <el-col :span="5" >
                <div style="color: #3e302d; font-weight: bold; font-size: 30px">
                  姓名
                </div>
                <div style="margin-top: 12px">
                  <el-input v-model="name" size="mini" style="border-color: red" placeholder="请输入姓名"
                    @change="getSearch2"></el-input>
                </div>
              </el-col>
              <el-col :span="6" :offset="1">
                <div style="color: #3e302d; font-weight: bold; font-size: 30px">
                  职位
                </div>
                <div style="margin-top: 12px">
                  <el-input v-model="post" size="mini" style="border-color: red" placeholder="请输入职位"
                    @change="getSearch2"></el-input>
                </div>
              </el-col>
              <el-col :span="5" :offset="1">
                <div style="color: #3e302d; font-weight: bold; font-size: 30px">
                  城市
                </div>
                <div style="margin-top: 12px">
                  <el-cascader size="mini" style="width: 100%;" :options="map" v-model="cityName" @change="getSearch3" 
                  :show-all-levels="false"
                    ref="cascaderItem1" :props="{ emitPath: false, value: 'value', label: 'title', leaf: 'value', }">
                    <template slot-scope="{ node, data }">
                      <span>{{ data.title }}</span>
                    </template>
                  </el-cascader>
                </div>
              </el-col>
              <el-col :span="4" :offset="1">
                <div style="height: 55px;"></div>
                <div style="width: 100%;">
                  <el-button style="width: 100px;background-color: #d2041a;border: none;color: #fff;height: 32px ;border-radius: 10px;" @click="getSearch2"  size="mini">搜索</el-button>
                  <el-button style="width: 100px;background-color: #d2041a;border: none;color: #fff;height: 32px ;border-radius: 10px;" @click="close"  size="mini">重置 </el-button>
                </div>
              </el-col>
            </el-row>
            <div style="width: 100%;margin-left: -20px;" class="hidden-sm-and-down">
              <div v-for="(item, index) in list" :key="index" style="float: left; width: 25%">
                <div style="
                    margin-top: 18px;
                    background-color: #f4f4f4;
                    border-radius: 10px;
                    margin-left: 20px;
                  ">
                  <el-image :src="item.avatar" style="width: 100%; border-radius: 10px; height: 300px"
                    fit="cover"></el-image>
                  <div style="
                      margin-left: 30px;
                      font-weight: bold;
                      font-size: 22px;
                      color: #3e302d;
                      margin-top: 15px;
                      margin-bottom: 10px;
                    ">
                    {{ item.name }}
                  </div>
                  <div style="
                      margin-left: 30px;
                      font-weight: 400;
                      font-size: 18px;
                      color: #3e302d;
                      margin-top: 10px;
                      margin-bottom: 10px;
                    ">
                    {{ item.post }}
                  </div>
                  <div style="
                      margin-left: 30px;
                      font-weight: 400;
                      font-size: 18px;
                      color: #3e302d;
                      margin-top: 10px;
                      padding-bottom: 10px;
                    ">
                    <i class="el-icon-location" style="font-size: 20px"></i>
                    <span style="font-size: 14px">{{ item.cityName }}</span>
                  </div>
                </div>
              </div>
            </div>

            <el-col :span="24">
              <div style="margin: 18px auto">
                <div :style="'width:' +
                  (Number((total / 8) * 2).toFixed(0) * 40 + 80) +
                  'px;margin:0 auto;'
                  ">
                  <el-pagination background layout="prev, pager, next" @current-change="getSearch" :page-size="8"
                    :total="total">
                  </el-pagination>
                </div>
              </div>
            </el-col>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="hidden-md-and-up" style="margin-top: 1rem;">
      <el-row>
        <el-col :span="22" :offset="1">
          <el-row type="flex" class="row-bg" justify="space-between">
            <el-col :span="7">
              <div style="color: #3e302d; font-weight: bold; font-size: 16px" @change="getSearch2">
                姓名
              </div>
              <div style="margin-top: 12px">
                <el-input v-model="name" class="miniApp" size="mini" style="border-color: red" placeholder="请输入姓名"></el-input>
              </div>
            </el-col>
            <el-col :span="7">
              <div style="color: #3e302d; font-weight: bold; font-size: 16px" @change="getSearch2">
                职位
              </div>
              <div style="margin-top: 12px">
                <el-input v-model="post" class="miniApp" size="mini" style="border-color: red" placeholder="请输入职位"></el-input>
              </div>
            </el-col>
            <el-col :span="7">
              <div style="color: #3e302d; font-weight: bold; font-size: 16px">
                城市
              </div>
              <div style="margin-top: 12px">
                <el-cascader size="mini" class="miniApp" :options="map" v-model="cityName" @change="getSearch3" 
                  ref="cascaderItem2" :props="{ emitPath: false, value: 'value', label: 'title', leaf: 'title', }">
                  <template slot-scope="{ node, data }">
                    <span>{{ data.title }}</span>
                  </template>
                </el-cascader>
              </div>
            </el-col>
           
           
          </el-row>
          <el-row>
            <el-col :span="7" :offset="0">
              <div style="margin-top: 12px">
               <el-button style="width: 100%;border-radius: 10px;background-color: #d2041a;border: none;color: #fff;" @click="getSearch4"  size="mini">搜索</el-button>
              </div>
            </el-col>
            <el-col :span="7" :offset="1">
              <div style="margin-top: 12px">
                <el-button style="width: 100%;border-radius: 10px;background-color: #d2041a;border: none;color: #fff;" @click="close"  size="mini">重置 </el-button>
              </div>
            </el-col>
          </el-row>
        </el-col>
        
        <el-col :span="22" :offset="1">
          <el-row type="flex" class="row-bg" justify="space-between" style="flex-wrap: wrap">
            <el-col :span="11" v-for="(item, index) in list" :key="index">
              <div style="
                  margin-top: 18px;
                  background-color: #f4f4f4;
                  border-radius: 10px;
                ">
                <el-image :src="item.avatar" style="width: 100%; border-radius: 10px; height: 150px"
                  fit="cover"></el-image>
                <div style="
                    margin-left: 10px;
                    font-weight: bold;
                    font-size: 18px;
                    color: #3e302d;
                    margin-top: 10px;
                    margin-bottom: 10px;
                  ">
                  {{ item.name }}
                </div>
                <div style="
                    margin-left: 10px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #3e302d;
                    margin-top: 5px;
                    margin-bottom: 10px;
                  ">
                  {{ item.post }}
                </div>
                <div style="
                    margin-left: 10px;
                    font-weight: 400;
                    font-size: 12px;
                    color: #3e302d;
                    margin-top: 5px;
                    padding-bottom: 10px;
                  ">
                  <i class="el-icon-location" style="font-size: 18px"></i>
                  <span style="font-size: 14px">{{ item.cityName }}</span>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="24">
          <div style="margin: 18px auto">
            <div :style="'width:' +
              (Number((total / 8) * 2).toFixed(0) * 40 + 80) +
              'px;margin:0 auto;'
              ">
              <el-pagination @current-change="getSearch" background layout="prev, pager, next" :page-size="8"
                :total="total">
              </el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="footer hidden-sm-and-down">
      <el-row>
        <el-col :span="13" :offset="8">
          <div style="max-width: 167px;height: 126px;margin-top: 63px; float: left;">
            <img src="../assets/logo.png" alt="" style="width: 100%;height: 126px;">
          </div>
          <div
            style="float: right;font-weight: bold;font-size: 36px;margin-top: 24px;margin-bottom: 24px;">
            <div>联系我们</div>
            <div style="
                width: 26rem;
                height: 2px;
                background-color: #000;
                margin-top: 12px;
                margin-bottom: 12px;
              "></div>
            <div style="margin-bottom: 12px; font-size: 22px; font-weight: 400">
              联系电话：{{ about.phone }}
            </div>
            <div style="margin-bottom: 12px; font-size: 22px; font-weight: 400">
              官方邮箱：{{ about.email }}
            </div>
            <div style="margin-bottom: 12px; font-size: 22px; font-weight: 400">
              公司地址：{{ about.address }}
            </div>
          </div>
        </el-col>
      </el-row>
      <div style="width: 100%; background-color: #2c2726; height: 60px">
        <el-row>
          <el-col :span="16" :offset="8">
            <div style="
                float: left;
                color: #ffffff;
                font-size: 16px;
                font-weight: 400;
                line-height: 60px;
                margin-left: -42px;
              ">
              ©2024 北京传承家办管理咨询有限公司
            </div>
            <div style="
                float: left;
                color: #ffffff;
                font-size: 16px;
                font-weight: 400;
                margin-left: 102px;
                line-height: 60px;
              ">
              备案：京ICP备2024066754号-2
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="footerApp  hidden-md-and-up" >
        <div :span="24" style="text-align: center;">
          <div style="font-size: 1rem;font-weight: bold">联系我们</div>
          <div style="width: 100%;margin: 0 auto;height: 2px;background-color:#D2041A  ;margin-top: 12px;margin-bottom: 12px;"></div>
          <div style="margin-bottom:12px ;font-size: 1rem;font-weight: 400;">联系电话：{{ about.phone }}</div>
          <div style="margin-bottom:12px ;font-size: 1rem;font-weight: 400;">官方邮箱：{{ about.email }}</div>
          <div style="margin-bottom:12px ;font-size: 1rem;font-weight: 400;">公司地址：{{ about.address }}</div>
        </div>
        <div :span="24" style="width: 100%;background-color: #2C2726;position: absolute;bottom: 0;">
          <div style="color: #FFFFFF;font-size: .8rem;font-weight: 400;text-align: center;margin-top: 20px;">©2024 北京传承家办管理咨询有限公司</div>
          <div style="color: #FFFFFF;font-size: .8rem;font-weight: 400;line-height: 45px;text-align: center">备案：京ICP备2024066754号-2</div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      activeIndex: 3,
      statusbar: false,
      about: {},
      banner: {},
      name: "",
      post: "",
      map: [],
      list: [],
      page: 1,
      total: 0,
      cityName: "",
      cityCode:''
    };
  },
  watch: {
    cityName(e) {
      if (!e) {
        this.cityCode = ''
       this.select('')
      }

    }
  },
  mounted() {
    document.title = "传家团队"; // 设置页面标题
    this.getData();
  },
  methods: {
    toLink(url) {
      console.log(url);
      this.$router.push({ path: url });
      this.statusbar = false;
    },
    close(){
      this.page = 1
      this.cityName = ''
      this.cityCode = ''
      this.post = ''
      this.name = ''
      this.total = 0
      this.$http
        .get(
          "api/desk/team-page?current=" +
          this.page +
          "&size=8&cityCode=" +
          this.cityCode +
          "&name=" +
          this.name +
          "&post=" +
          this.post
        )
        .then((res) => {
          if (res.code == 200) {
            this.list = res.data.records;
            this.total = res.data.total;
          }
        });
    },
    getSearch4(e){
      let obj = this.$refs['cascaderItem2'].getCheckedNodes()[0]
      this.cityCode = obj.data.id
      this.$http
        .get(
          "api/desk/team-page?current=" +
          this.page +
          "&size=8&cityCode=" +
          this.cityCode +
          "&name=" +
          this.name +
          "&post=" +
          this.post
        )
        .then((res) => {
          if (res.code == 200) {
            this.list = res.data.records;
            this.total = res.data.total;
          }
        });
    },
    getSearch2(e) {
      console.log(1111)
      if(this.cityName == null){
        this.cityName = ''
        this.$http
          .get(
            "api/desk/team-page?current=" +
            this.page +
            "&size=8&cityCode=" +
            this.cityCode +
            "&name=" +
            this.name +
            "&post=" +
            this.post
          )
          .then((res) => {
            if (res.code == 200) {
              this.list = res.data.records;
              this.total = res.data.total;
            }
          });
        return false
     }else{
      let obj = this.$refs['cascaderItem1'].getCheckedNodes()[0]
      this.cityCode = obj.data.id
      this.$http
        .get(
          "api/desk/team-page?current=" +
          this.page +
          "&size=8&cityCode=" +
          this.cityCode +
          "&name=" +
          this.name +
          "&post=" +
          this.post
        )
        .then((res) => {
          if (res.code == 200) {
            this.list = res.data.records;
            this.total = res.data.total;
          }
        });
      }
    },
    getSearch3(value, c) {
     if(this.cityName == null){

      this.$http
        .get(
          "api/desk/team-page?current=" +
          this.page +
          "&size=8&cityCode=" +
          this.cityCode +
          "&name=" +
          this.name +
          "&post=" +
          this.post
        )
        .then((res) => {
          if (res.code == 200) {
            this.list = res.data.records;
            this.total = res.data.total;
          }
        });
        return false
     }else{
      console.log(222)
      let obj = this.$refs['cascaderItem1'].getCheckedNodes()[0]
      this.cityCode = obj.data.id
      this.$http
        .get(
          "api/desk/team-page?current=" +
          this.page +
          "&size=8&cityCode=" +
          this.cityCode +
          "&name=" +
          this.name +
          "&post=" +
          this.post
        )
        .then((res) => {
          if (res.code == 200) {
            this.list = res.data.records;
            this.total = res.data.total;
          }
        });
     }
    
    },
    getSearch(e) {
      this.page = e;
      this.$http
        .get(
          "api/desk/team-page?current=" +
          this.page +
          "&size=8&cityName=" +
          this.cityCode +
          "&name=" +
          this.name +
          "&post=" +
          this.post
        )
        .then((res) => {
          if (res.code == 200) {
            this.list = res.data.records;
            this.total = res.data.total;
          }
        });
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    select(e){
      this.$http
        .get(
          "api/desk/team-page?current=" +
          this.page +
          "&size=8&cityCode=" +
          e +
          "&name=" +
          this.name +
          "&post=" +
          this.post
        )
        .then((res) => {
          if (res.code == 200) {
            this.list = res.data.records;
            this.total = res.data.total;
          }
        });
    },
    getData() {
      this.$http
        .get(
          "api/desk/team-page?current=" +
          this.page +
          "&size=8&cityCode=" +
          this.cityCode +
          "&name=" +
          this.name +
          "&post=" +
          this.post
        )
        .then((res) => {
          if (res.code == 200) {
            this.list = res.data.records;
            this.total = res.data.total;
          }
        });
      this.$http.get("api/desk/banner-list?detailType=5").then((res) => {
        if (res.code == 200) {
          this.banner = res.data[0];
        }
      });
      this.$http.get("api/desk/region-tree").then((res) => {
        if (res.code == 200) {
          console.log(res.data);
          this.map = res.data;
        }
      });
      this.$http.get("api/desk/contact-us").then((res) => {
        if (res.code == 200) {
          this.about = res.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>

::v-deep .el-input__inner {
  border-color: #f4f4f4 !important;
  border-radius: 10px;
  background-color: #f4f4f4 !important;
  height: 36px;

  &:focus {
    outline: none;
    /* 移除默认的点击样式 */
    border-color: #f4f4f4 !important;
  }
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #d20419;
  border-radius: 50%;
}

::v-deep .el-pagination.is-background .el-pager li {
  border-radius: 50%;
}

::v-deep .el-pagination.is-background .btn-next,
::v-deep .el-pagination.is-background .btn-prev,
::v-deep .el-pagination.is-background .el-pager li {
  border-radius: 50% !important;
}

::v-deep .el-pager .active::after {
  display: none;
}
@media screen and (max-width:900px) {
  ::v-deep .el-input__inner {
  border-radius: 10px;
  height: 26px;
  }
}
</style>

<style>
.top {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.line-container {
  margin-top: 25px;
  float: right;
}

.line {
  width: 35px;
  /* 横线的宽度 */
  height: 2px;
  /* 横线的高度 */
  margin-top: 10px;
  background-color: #3e302d;
  /* 横线的颜色 */
}

.line:not(:last-child) {
  margin-right: 10px;
  /* 横线之间的间隔 */
}

.appMenu {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  position: relative;
  cursor: pointer;
  margin-bottom: 50px;
}

.appActive::after {
  content: "";
  display: block;
  width: 53px;
  height: 4px;
  background: #f4ad05;
  /* 边框的颜色 */
  position: absolute;
  left: 50%;
  margin-top: 10px;
  border-radius: 2px;
  transform: translateX(-50%);
}

.menu {
  color: #3e302d;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  position: relative;
  cursor: pointer;
}

.active::after {
  content: "";
  display: block;
  width: 53px;
  height: 4px;
  background: #f4ad05;
  /* 边框的颜色 */
  position: absolute;
  left: 50%;
  margin-top: -18px;
  border-radius: 2px;
  transform: translateX(-50%);
}

.left {
  float: left;
}

.right {
  float: right;
}

.logoBox {
  width: 74px;
  height: 54px;
}

.logo {
  width: 100%;
  height: 54px;
  line-height: 88px;
  cursor: pointer;
}

.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 2px solid #f4ad05 !important;
}
</style>