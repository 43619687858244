
import Vue from 'vue'
import Home from '../view/home.vue';
import Business from '../view/business.vue';
import Team from '../view/team.vue';
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Home
  },
  {
    path: '/business',
    name: 'business',
    component: Business
  },
  {
    path: '/team',
    name: 'team',
    component: Team
  },
  // 其他路由配置...
];
 
const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: routes
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title; // 设置页面标题
  }
  return VueRouterPush.call(this, to).catch(err => err)
}
 
export default router;