import Vue from 'vue'
import App from './App.vue'

import router from './router';


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';

import http from '@/utils/request'

Vue.config.productionTip = false
Vue.prototype.$http = http

Vue.use(ElementUI);

new Vue({
  el: '#app',
  router,
  render: h => h(App)
})


