
import axios from 'axios'
const request = axios.create({
  baseURL: '/',   // 设置请求的基础URL，即后端接口地址
  timeout: 3000000                       // 设置请求超时时间为30秒
})


request.interceptors.request.use(config => {
  console.log(config)
  // 设置请求头，指定请求内容类型为JSON
  config.headers['Content-Type'] = 'application/json;charset=utf-8';
  
  // 从localStorage中获取用户token，如果不存在则默认为空对象
  let user = JSON.parse(localStorage.getItem("userToken") || '{}')
  // 将token添加到请求头中，以便后端进行验证
  config.headers['token'] = user.token;

  // 返回处理后的config对象，以便继续请求
  return config;
}, error => {
  // 如果请求发生错误，打印错误信息以便调试
  console.error('request error: ' + error);
  // 返回Promise.reject以通知请求发生错误
  return Promise.reject(error);
});


// response 拦截器
// 这个拦截器可以在接口响应后对结果进行统一处理
request.interceptors.response.use(
  response => {
      // 获取响应数据
      let res = response.data;
      // 兼容处理服务端返回的字符串数据，将其解析为JSON对象
      if (typeof res === 'string') {
          res = res ? JSON.parse(res) : res;
      }

      // 检查响应中的状态码，如果为401则表示未授权
      if(res.code === '401'){
          // 如果未授权，则重定向到登录页面
          router.push('/login');
      }

      // 返回处理后的响应数据
      return res;
  },
  error => {
      // 如果响应发生错误，打印错误信息以便调试
      console.error('response error: ' + error);
      // 返回Promise.reject以通知响应发生错误
      return Promise.reject(error);
  }
)

// 导出配置好的axios实例，以便在其他模块中使用
export default request;